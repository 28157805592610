// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("/Users/djohnson/Desktop/Github/trueme/landing-page/node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-index-tsx": () => import("/Users/djohnson/Desktop/Github/trueme/landing-page/src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-privacy-policy-index-tsx": () => import("/Users/djohnson/Desktop/Github/trueme/landing-page/src/pages/privacy-policy/index.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-index-tsx" */),
  "component---src-pages-support-index-tsx": () => import("/Users/djohnson/Desktop/Github/trueme/landing-page/src/pages/support/index.tsx" /* webpackChunkName: "component---src-pages-support-index-tsx" */),
  "component---src-pages-terms-index-tsx": () => import("/Users/djohnson/Desktop/Github/trueme/landing-page/src/pages/terms/index.tsx" /* webpackChunkName: "component---src-pages-terms-index-tsx" */),
  "component---src-pages-thanks-index-tsx": () => import("/Users/djohnson/Desktop/Github/trueme/landing-page/src/pages/thanks/index.tsx" /* webpackChunkName: "component---src-pages-thanks-index-tsx" */)
}

