module.exports = [{
      plugin: require('/Users/djohnson/Desktop/Github/trueme/landing-page/node_modules/gatsby-plugin-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-115101125-5"},
    },{
      plugin: require('/Users/djohnson/Desktop/Github/trueme/landing-page/node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/Users/djohnson/Desktop/Github/trueme/landing-page/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
